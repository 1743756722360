/** @jsx jsx */
import * as React from "react"
import { jsx } from "theme-ui"
import GetOutboundLink from "./get-outbound-link"
import HoverList from './hover-list'
import { OutlineButton } from "../components/button"
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import * as styles from './header.module.scss'
import commonUtil from "../utils/common-util"
import AppContext from '../context/AppContext'

const Header = ({
  transparent,
  width,
  recalculationOnResize,
  mobileMenutoggle,
  items = [{
    label: 'Nothing to click yet...'
  }]
}) => {
  // console.log('Menu.opened : ', opened)
  // console.log('Menu.toggle : ', toggle)
  // console.log('Header -> items : ', items)
  // console.log('Header -> width : ', width)
  // console.log('Header -> transparent : ', transparent)

  const {
    defineViewability,
    highlights,
    instructions,
  } = React.useContext(AppContext);

  const isShownAppLaunchBtn = commonUtil.isValidLink(process.env.LAUNCH_APP_BUTTON)

  const locateItemEventAction = (mItem) => {

    const {
      eventProps,
      ...mItemProps
    } = mItem

    const locatedEventProps = eventProps ?
      {
        ...eventProps,
        event_action: eventProps.event_action.replace('{location}', 'Header')
      } : null

    return {
      ...(eventProps && locatedEventProps !== null ?
        {
          eventProps: locatedEventProps
        } :
        null
      ),
      ...mItemProps
    }
  }

  const localiseMenuItem = (mItem) => {

    const { items, ...mItemProps } = mItem
    
    const locatedItems = items !== undefined ?
      [...items.map((subMItem) => locateItemEventAction(subMItem))] :
      null
    
    return {
      ...locateItemEventAction(mItemProps),
      ...(items && locatedItems !== null ? {
        items: locatedItems
      } : null)
    }
  }

  console.log('Header -> items : ', items)
  
  const menuItems = items.map((mItem) => localiseMenuItem(mItem))

  console.log('Header -> menuItems : ', menuItems)

  const menuItemAreas = menuItems.reduce((prevObj, currObj) => {

      if (currObj.area !== undefined) {
          if (prevObj.length === 0 || prevObj.indexOf(currObj.area) < 0) {
              prevObj.push(currObj.area);
          }
      }

      return prevObj;

  },[]);

  console.log('Header -> menuItemAreas : ', menuItemAreas)

  const checkIsInternalLink = (url) => {
    return url !== undefined && url.indexOf('http://') < 0 && url.indexOf('https://') < 0
  }
  const headerItemGen = ({eventProps, href, target, onClick, label, className, anchor, index, keyPrefix }) => {
    /*
      label: 'Docs',
      href: '/404',
      target: '_self',
      eventProps: {...}
    */
    /*
      <AnchorLink to="/#about">About</AnchorLink>
    */
    if (href && checkIsInternalLink(href) && anchor) {
      console.log('AnchorLink : eventProps => ', eventProps)
      console.log('AnchorLink : className => ', className)
      return (
        <AnchorLink
          to={href + '/'+ anchor}
          className={
            className ? 'anchor-link ' + className : 'anchor-link'
          }
          onAnchorLinkClick={(eProps)=>{
            // console.log('AnchorLink Click Callback : eProps => ', eProps)
            // console.log('AnchorLink Click Callback : window => ', window)
            if (typeof window !== "undefined" && window.gtag) {
              // console.log('AnchorLink Click Callback : window.gtag => ', window.gtag)
              commonUtil.winGTagOperation({
                gTag: window.gtag,
                eventCategory: eventProps.event_category,
                eventAction: eventProps.event_action,
                eventLabel: eventProps.event_label,
                ...(eventProps.event_value !== undefined ? {
                  eventValue: eventProps.event_value
                }:null),
                transportType: ''
              })
            }
          }}
        >{
          label
        }</AnchorLink>
      )
    } else
    return (
      <GetOutboundLink
        {...(
          index !== undefined ?
            {
              key: keyPrefix !== undefined ? keyPrefix + index : index
            } : null
        )}
        {...(eventProps ?
          {
            eventProps: eventProps
          } : null
        )}
        {...(href ?
          {
            href: href
          } : null
        )}
        {...(target ?
          {
            target: target
          } : null
        )}
        {...(onClick ?
          {
            funcOnClick: onClick
          } : null
        )}
        {...(className ?
          {
            className: className
          } : null
        )}
      >{label}</GetOutboundLink>
    )
  }
  const headerListGen = ({caption, additionalClassName, list}) => {
    return (
      <HoverList
        {...{
          ...(caption ? {caption: caption}: null),
          ...(additionalClassName ? {additionalClassName: additionalClassName}: null),
          ...(list ? {list: list}: null)
        }}  
      />
    )
  }
  return (
    <div
      sx={{
        width: !recalculationOnResize ?
          `100%` :
          width && width !== null ?
            width : `100%`,
      }}
      className={styles.container}
    >
      <div sx={{
        backgroundPositionY: transparent === false ? `0rem` : `-3.12rem`,
        '.mobile-menu': {
          display: [`block`, null, `none`],
          position: `relative`,
        },
        '.desktop-menu': {
          display: [`none`, null, `flex`],
        }
      }} className={styles.inner}>
        <GetOutboundLink className={styles.siteLogo} href="/">
          <img src="/logo-platypus-v2-icon-only.png" sx={{
            width: [`3.111rem`],
            height: [`3.111rem`]
          }}/>
        </GetOutboundLink>
        <div className="mobile-menu">
          {instructions['on-mobile-menu-open-button'] !== undefined && <div class="text-fluor-green text-note instruction-wrapper">{instructions['on-mobile-menu-open-button']}</div>}
          <GetOutboundLink
            sx={{
              'img': {
                width: `1.5rem`,
                height: `1.5rem`,
              }
            }}
            funcOnClick={()=>{ mobileMenutoggle() }}
            eventProps={{
              event_action: 'Click on Header',
              event_category: 'Navigation',
              event_label: 'Open Mobile Menu'
            }}
          ><img src="/icon-menu.png"/></GetOutboundLink>
        </div>
        {
          menuItemAreas.length > 0 ? menuItemAreas.map((areaKey) => {
            const areaItems = menuItems.filter((itm) => {
              return itm.area !== undefined && itm.area === areaKey;
            })
            console.log('Header -> Area Items to be Generated : ', areaItems)
            console.log('Header : areaKey => ', areaKey)
            console.log('AppContext : highlights => ', highlights)
            console.log('Header : highlights[areaKey] => ', highlights[areaKey])
            return (
              <div key={'header-' + areaKey} className={'desktop-menu ' + areaKey}>{
                areaItems.map((areaItm, areaItmIndex) => {
                  console.log(`Writing Item of ${areaKey} : `, areaItmIndex)
                  return areaItm.viewability !== undefined &&
                    defineViewability !== undefined &&
                    typeof defineViewability === 'function' &&
                    defineViewability(areaItm.viewability,0) ?
                      headerItemGen({
                        ...areaItm,
                        index: areaItmIndex,
                        keyPrefix: 'header-'+ areaKey +'-menu-item-',
                        ...(
                          highlights !== undefined && highlights[areaKey] !== undefined && parseInt(highlights[areaKey]) ===  areaItmIndex ?
                            {
                              className: 'active'
                            } :
                            null
                        )
                      })
                      : null
                })
              }</div>
            )
          }) : null
        }
        <div className="desktop-menu">
          <div className="tag-links">
            {items && menuItems && menuItems.map((item, index) => {

              return item.viewability !== undefined &&
                defineViewability !== undefined &&
                typeof defineViewability === 'function' &&
                !item.area &&
                defineViewability(item.viewability,0) ?
                  item.items &&
                  item.items.length > 0 ?
                    headerListGen({
                      caption: item.label,
                      additionalClassName: 'tag-link',
                      list: item.items,
                      index: index
                    }) :
                    headerItemGen({ ...item, index: index, keyPrefix: 'header-menu-item-' })
                  : null
            })}
          </div>
          {isShownAppLaunchBtn === true && (<OutlineButton gTagEvent={{
              event_action: 'Click on Header',
              event_category: 'Direct to Product (mainnet)',
              event_label: 'Launch App (Beta)'
            }}
            ribbonNote={'Beta'}
            url={process.env.LAUNCH_APP_BUTTON} size={'small'}
          >Launch App</OutlineButton>)}
        </div>
          {/* <div className="tag-links">
            {items && menuItems && menuItems.map((item, index) => {
              return item.items && item.items.length > 0 ?
                headerListGen({
                  caption: item.label,
                  additionalClassName: 'tag-link',
                  list: item.items
                }) :
                headerItemGen({ ...item })
            })}
          </div> */}
        
      </div>
    </div>
  )
}

export default Header