/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import { Global } from "@emotion/react"
import SEO from "./seo"
// import {Layout as ThemeLayout} from "@lekoarts/gatsby-theme-cara/src/components/layout"
import AppContext from '../../../context/AppContext'
import "../../../css/global.scss"
import Header from "../../../components/header"
import MobileMenu from "../../../components/mobile-menu"
import OverlayLoading from "../../../components/overlay-loading"

export default function Layout({
  children,
  className,
  sitePath,
  siteTitle,
  siteDescription,
  siteImage,
  siteTitleTemplate,
  pageContext,
  ...props
}) {
  // console.log('>>>>>> ?? Layout Props ?? <<<<<< : ', props)
  // console.log('>>>>>> ?? Layout path ?? <<<<<< : ', path)
  // console.log('>>>>>> ?? Layout pageContext ?? <<<<<< : ', pageContext)
  // if ('>>>>>> ??? <<<<<< : ', navigator !== undefined) {
  //   console.log(navigator)
  // }
  const { hideNavi } = pageContext !== undefined && pageContext.hideNavi !== undefined ? pageContext : { hideNavi : false }
  
  // console.log('>>>>>> ?? Layout pageContext.hideNavi ?? <<<<<< : ', hideNavi)

  const { items, addMenuItem } = React.useContext(AppContext);
  return (
    <React.Fragment>
      <Global
        styles={(theme) => ({
          "*": {
            boxSizing: `inherit`,
            "&:before": {
              boxSizing: `inherit`,
            },
            "&:after": {
              boxSizing: `inherit`,
            },
          },
          html: {
            // fontSize: [`16px`,`16px`,`18px`,`18px`],
            WebkitTextSizeAdjust: `100%`,
          },
          img: {
            borderStyle: `none`,
          },
          pre: {
            fontFamily: `monospace`,
            fontSize: `1em`,
          },
          "[hidden]": {
            display: `none`,
          },
          "::selection": {
            backgroundColor: theme.colors.primary,
            color: theme.colors.background,
          },
        })}
      />
      <SEO
        {...(
          siteTitle ? {
            title: siteTitle
          }: null
        )}
        {...(
          siteDescription ? {
            description: siteDescription
          }: null
        )}
        {...(
          sitePath ? {
            pathname: sitePath
          }: null
        )}
        {...(
          siteImage ? {
            image: siteImage
          }: null
        )}
        {...(
          siteTitleTemplate ? {
            titleTemplate: siteTitleTemplate
          }: null
        )}
      />
      <noscript>{`
        <img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=205893724889968&ev=PageView&noscript=1"
        />
      `}</noscript>
      <script async defer src="https://tools.luckyorange.com/core/lo.js?site-id=87135893"></script>
      <AppContext.Consumer>
        {aContext => {
          console.log('aContext')
          console.log(aContext)
          return [
            <main
              key={'page-main'}
              sx={{
                transition: `opacity 0.3s ease-in-out`,
                opacity: aContext.opened || aContext.isLoading ? 0.4 : 1,
              }}
              {...props} className={className}
            >
              {children}
            </main>,
            ...(!hideNavi ? [
              <Header
                key={'page-header'}
                transparent={aContext.headerTransparent}
                recalculationOnResize={aContext.recalculationOnResize}
                width={aContext.headerWidth}
                mobileMenutoggle={aContext.toggleMenu}
                items={aContext.items}
              />,
              <MobileMenu
                key={'page-overlay-menu'}
                opened={aContext.opened}
                toggle={aContext.toggleMenu}
                items={aContext.items}
              />
            ] : []),
            <OverlayLoading
              key={'page-overlay-loading'}
              isLoading={aContext.isLoading}
              message={aContext.loadingMessage}
            />
          ]
        }}
      </AppContext.Consumer>
    </React.Fragment>
  )
}