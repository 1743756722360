// extracted by mini-css-extract-plugin
export var buttonRoot = "button-module--button-root--26qef";
export var buttonContainer = "button-module--button-container--2CKsT";
export var buttonContainerSmall = "button-module--button-container-small--yIuez";
export var buttonOutline = "button-module--button-outline--2XXOw";
export var buttonOutlineSmall = "button-module--button-outline-small--3IS3-";
export var buttonSecondaryOutline = "button-module--button-secondary-outline--1LmUZ";
export var buttonSecondaryOutlineSmall = "button-module--button-secondary-outline-small--1xAsy";
export var buttonNftOutline = "button-module--button-nft-outline--1CCJ_";
export var buttonNftOutlineSmall = "button-module--button-nft-outline-small--3dVRz";
export var buttonRibbon = "button-module--button-ribbon--17DLr";
export var buttonRibbonInner = "button-module--button-ribbon-inner---GQVP";
export var buttonRibbonInnerWithSuffix = "button-module--button-ribbon-inner-with-suffix--2-ZpS";
export var buttonLabelSuffix = "button-module--button-label-suffix--2PeKY";
export var buttonInner = "button-module--button-inner--1tqdj";
export var buttonLabel = "button-module--button-label--3bMhK";
export var buttonLabelSmall = "button-module--button-label-small--1g4OA";