/** @jsx jsx */
import * as React from "react"
import { jsx } from "theme-ui"
import GetOutboundLink from "./get-outbound-link"
import { OutlineButton } from "../components/button"
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import * as styles from './mobile-menu.module.scss'
import commonUtil from "../utils/common-util"
import AppContext from "../context/AppContext"

const MobileMenu = ({
  opened,
  toggle,
  items = [{
    label: 'Nothing to click yet...'
  }]
}) => {
  // console.log('%%%O%%%')
  // console.log('%O%O%O%')
  // console.log('%%OOO%%')
  // console.log('%%%O%%%')
  // console.log('Menu.opened : ', opened)
  // console.log('Menu.toggle : ', toggle)
  // console.log('Menu.items : ', items)
  const {
    defineViewability,
    resetInstructions
  } = React.useContext(AppContext);

  const checkIsInternalLink = (url) => {
    return url !== undefined && url.indexOf('http://') < 0 && url.indexOf('https://') < 0
  }

  const isShownAppLaunchBtn = commonUtil.isValidLink(process.env.LAUNCH_APP_BUTTON)

  const menuItemGen = ({eventProps, href, target, onClick, label, anchor, className, isInternalLink}) => {

    // console.log('░▒▓░▓▓▒▒▒▒▒▓▓▒▒▒▒▒▓▓░▓▒░');
    // console.log('▓▒░ ░▒▒▓▓░░▒▒░░▓▓▒▒░ ░▒▓');
    // console.log('▒░ ░ ░▒▒▓▓░░░░▓▓▒▒░ ░ ░▒');
    // console.log('░ Mobile Menu Item Generator : Class => ', className);

    if (href && checkIsInternalLink(href) && anchor) {
      return (
        <AnchorLink
          to={href + '/'+ anchor}
          className={
            className ? 'anchor-link ' + className : 'anchor-link'
          }
          onAnchorLinkClick={(eProps)=>{
            // console.log('AnchorLink Click Callback : eProps => ', eProps)
            // console.log('AnchorLink Click Callback : window => ', window)
            toggle()
            resetInstructions()
            if (typeof window !== "undefined" && window.gtag) {
              // console.log('AnchorLink Click Callback : window.gtag => ', window.gtag)
              commonUtil.winGTagOperation({
                gTag: window.gtag,
                eventCategory: eventProps.event_category,
                eventAction: eventProps.event_action,
                eventLabel: eventProps.event_label,
                ...(eventProps.event_value !== undefined ? {
                  eventValue: eventProps.event_value
                }:null),
                transportType: ''
              })
            }
          }}
        >{
          label
        }</AnchorLink>
      )
    } else
    return (
      <GetOutboundLink
        {...(eventProps ?
          {
            eventProps: eventProps
          } : null
        )}
        {...(href ?
          {
            href: href
          } : null
        )}
        {...(target ?
          {
            target: target
          } : null
        )}
        {...(onClick ?
          {
            funcOnClick: isInternalLink ?
              onClick :
              ()=>{
                onClick()
                toggle()
              }
          } : {
            funcOnClick: ()=>toggle()
          }
        )}
      >{label}</GetOutboundLink>
    )
  }
  return (
    <div
      sx={{
        opacity: opened ? 1 : 0,
        pointerEvents: !opened ? `none` : `auto`,
        'a, p': {
          fontSize: `1.25rem`
        },
        p: {
          fontWeight: `500`, opacity: 0.6
        }
      }}
      className={styles.container}
    >
    <div sx={{
        transform: opened ? `translateY(0)` : `translateY(42%)`,
        'a.anchor-link': {
          padding: `0.15rem 0 0.05rem!important`,
          justifyContent: `flex-start`,
        },
        'li.last-anchor-link': {
          // paddingBottom: `1rem`,
          // borderBottom: `2px solid #FFFFFF`

        }
      }}
    className={styles.inner}>
      <ul
        className={styles.menuContent}
      >
        {items.map(({className: mItemClassName, ...mItem}, mIdx) => {
          const isInternalLink = checkIsInternalLink(mItem.href)
          // console.log('▓▒░ ░▒▒▓▓░░▒▒░░▓▓▒▒░ ░▒▓');
          // console.log('▒░ ░ ░▒▒▓▓░░░░▓▓▒▒░ ░ ░▒');
          // console.log('░ ░▒░ ░▒▒▓▓░░▓▓▒▒░ ░▒░ ░');
          // console.log(styles.menuItemGroup);
          // console.log('mItemClassName => ', mItemClassName);
          // console.log('typeof mItemClassName => ', typeof mItemClassName);
          return mItem.viewability !== undefined &&
            defineViewability !== undefined &&
            typeof defineViewability === 'function' &&
            defineViewability(mItem.viewability,2) ?
            (
              <li
                key={'mobile-menu-item-' + mIdx}
                className={
                  mItem.items ?
                    styles.menuItemGroup :
                    mItemClassName !== undefined && typeof mItemClassName === 'string' && mItemClassName.indexOf('last-') >= 0 ?
                      styles.menuLastItem :
                      styles.menuItem
                }
              >
                {
                  mItem.items ? [
                    <p key={'mobile-menu-item-' + mIdx + '-label'}>{mItem.label}</p>,
                    <ul key={'mobile-sub-menu-' + mIdx} className={styles.menuSubContent}>
                      {mItem.items.map((subMItem, subMIdx) => {
                        const isSubInternalLink = checkIsInternalLink(subMItem.href)
                        return (<li key={'mobile-sub-menu-item-' + subMIdx}>
                          {menuItemGen({
                            ...subMItem,
                            isInternalLink: isSubInternalLink
                          })}
                        </li>)
                      })}
                    </ul>
                  ] : menuItemGen({
                    ...mItem,
                    isInternalLink
                  })
                }
              </li>
            ) : null;
        })}
      </ul>
      {isShownAppLaunchBtn === true && (
        <div sx={{
          marginTop: `2rem`
        }}>
          <OutlineButton
            gTagEvent={{
              event_action: 'Click on Header',
              event_category: 'Direct to Product (mainnet)',
              event_label: 'Launch App (Beta)'
            }}
            ribbonNote={'Beta'}
            url={process.env.LAUNCH_APP_BUTTON} size={'small'}
          >Launch App</OutlineButton>
        </div>
      )}
      </div>
      <GetOutboundLink
        className={styles.toggleTrigger}
        funcOnClick={() => { toggle() }}
        eventProps={{
          event_action: 'Click on Mobile Menu',
          event_category: 'Navigation',
          event_label: 'Close Mobile Menu'
        }}
      ><img src="/icon-cross.png"/></GetOutboundLink>
    </div>
  )
}

export default MobileMenu