const isValidLink = (_str) => {

  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); 

  // return _str !== '' &&
  // (_str.indexOf('https://') > 0 || _str.indexOf('http://') > 0)

  return !!pattern.test(_str)
}

const stringConfigToProspSet = ({
  string = '',
  numericAttrNames = []
}) => {
  return string !== undefined && string.indexOf('=') >= 0 ? string.split('|').reduce((a, v) => {
    const valSet = v.split('=')
    return { ...a, [valSet[0]]: valSet.length > 1 ?
        numericAttrNames.indexOf(valSet[0]) >= 0 ?
            parseInt(valSet[1]) : valSet[1]
        : valSet[0]}
  }, {}) : {}
}

const winGTagOperation = ({gTag, eventCategory, eventAction, eventLabel, eventValue, transportType, callBackExecu}) => {

  let timeoutOwner;
  let timerActive = false;
  
  if (callBackExecu) {
    timerActive.current = true
    timeoutOwner = setTimeout(function () {
      timerActive.current = false
      callBackExecu()
    }, 800);
  }

  try {
    gTag(`event`, eventAction || `click`, {
      send_to: "GOOGLE_ANALYTICS_ID",
      event_category: eventCategory,
      event_label: eventLabel,
      transport_type: transportType,
      ...(eventValue !== undefined && typeof eventValue === 'number' ? {
        value: eventValue
      } : null),
      ...(callBackExecu ? {
        event_callback: function () {
          if (timerActive.current) {
            clearTimeout(timeoutOwner)
            timerActive.current = false
            callBackExecu()
          }
        }
      } : null),
    })
  } catch(error){
    if (callBackExecu) {
      if (timerActive.current) {
        clearTimeout(timeoutOwner)
        timerActive.current = false
        callBackExecu()
      }
    }
  }
}

export default { isValidLink, stringConfigToProspSet, winGTagOperation }