/** @jsx jsx */
import * as React from "react"
import { jsx } from "theme-ui"
import {
  buttonContainer,
  buttonContainerSmall,
  buttonOutline,
  buttonOutlineSmall,
  buttonSecondaryOutline,
  buttonSecondaryOutlineSmall,
  buttonNftOutline,
  buttonNftOutlineSmall,
  buttonInner,
  buttonLabel,
  buttonLabelSmall,
  buttonRibbon,
  buttonRibbonInner,
  buttonRibbonInnerWithSuffix,
  buttonLabelSuffix,
} from './button.module.scss'

const Button = ({
  children,
  url,
  onClick,
  size = 'regular',
  style = 'solid',
  fill = 'primary',
  gTagEvent,
  /* {
    event_category: null,
    event_action: null,
    event_label: null,
    event_value: null,
  } */
  onMouseEnter,
  onMouseLeave,
  ribbonNote,
  labelSuffix,
  ...props
}) => {
  const isExternalLink = url !== undefined && (url.indexOf('http://') >= 0 || url.indexOf('https://') >= 0);
  const styles = {
    container: {
      primary: {
        regular: buttonContainer,
        small: buttonContainerSmall
      },
      secondary: {
        regular: buttonContainer,
        small: buttonContainerSmall
      }
    },
    outline: {
      primary: {
        regular: buttonOutline,
        small: buttonOutlineSmall
      },
      secondary: {
        regular: buttonSecondaryOutline,
        small: buttonSecondaryOutlineSmall
      },
      nft: {
        regular: buttonNftOutline,
        small: buttonNftOutlineSmall
      }
    },
    label: {
      regular: buttonLabel,
      small: buttonLabelSmall
    }
  }

  let timeoutOwner;
  let timerActive = React.useRef(false);

  const triggerOnClickFunc = (callback) => {
    if (callback) {
      if (typeof callback === `function`) {
        callback()
      }
      else if (Array.isArray(callback)) {
        callback.foreach((func)=>func())
      }
    }
  }

  const openUrl = (redirect, url, callback) => {
    if (redirect) {
      document.location = url
    } else if (typeof window !== "undefined" && window.open) {
      window.open(url, '_blank');
      if (callback) {
        triggerOnClickFunc(callback)
      }
    }
  }

  const gTagClick = (e) => {
    console.log('>>> click >>> ', e)

    // console.table(gTagEvent)

    const {
      event_action = 'click', // ie. video play, video pause, Click on Hero
      event_category = 'button', // ie. video, Follower Aquisition
      event_label, // ie. label of the button, video title
      event_value,
      event_callback,
    } = gTagEvent

    const callbackCollector = [
      ...(onClick ? [ onClick ] : []),
      ...(event_callback ? [ event_callback ] : [])
    ]

    console.log(`event_action`, event_action)
    console.log(`event_category`, event_category)
    console.log(`event_label`, event_label)
    console.log(`event_value`, event_value)
    console.log(`event_callback`, event_callback)
    console.log(`children`, children)
    console.log(`typeof children`, typeof children)
    console.log(`children`, children.innerHTML)
    console.log(`callbackCollector`, callbackCollector)

    let redirect = true
    if (
      e.button !== 0 ||
      e.altKey ||
      e.ctrlKey ||
      e.metaKey ||
      e.shiftKey ||
      e.defaultPrevented
    ) {
      redirect = false
    }
    if (props.target && props.target.toLowerCase() !== `_self`) {
      redirect = false
    }
    // console.log("###%$@$%### MarkupButton : redirect => ", redirect)
    // alert('redirect is => '+redirect);

    const callbackExec = () => {
      if (isExternalLink) {
        openUrl(...[redirect, url, ...(
          callbackCollector.length > 0 ?
            callbackCollector.length < 2 ?
              callbackCollector :
              [callbackCollector] : []
        )])
      } else if (callbackCollector.length > 0) {
        triggerOnClickFunc(
          callbackCollector.length < 2 ?
            callbackCollector[0] :
            callbackCollector
        )
      }
    }

    if (typeof window !== "undefined" && window.gtag) {
      console.log(`GTag Event Triggered !!!`)
      timerActive.current = true
      timeoutOwner = setTimeout(function(){
        timerActive.current =false
        callbackExec();
      }, 800);
      try {
        window.gtag(`event`, event_action, {
          event_category: event_category,
          event_label: event_label !== undefined ?
            event_label :
            typeof children === `string` ?
              children :
              isExternalLink ?
                url : 'BUTTON LABEL WAS NOT GIVEN',
          ...(event_value !== undefined && typeof event_value === 'number' ? {
            value: event_value
          } : null),
          transport_type: redirect ? `beacon` : ``,
          event_callback: function(e) {
            if (timerActive.current) {
              clearTimeout(timeoutOwner)
              timerActive.current = false
              callbackExec();
            }
          },
        }) 
      } catch(error){
        console.log(`Error Triggered !!!`, error)
        if (timerActive.current) {
          clearTimeout(timeoutOwner)
          timerActive.current = false
          callbackExec()
        }
      }
    } else {
      console.log(`Directly Called Callback !!!`)
      callbackExec()
    }
  }

  return (
    <a
      className={styles[style === 'outline' ? 'outline' : 'container'][fill === 'secondary' ? 'secondary' : fill === 'nft' ? 'nft' : 'primary'][size] || styles.primary.container.regular}
      onMouseEnter={(e) => {
        e.preventDefault()
        if (onMouseEnter !== undefined) {
          onMouseEnter(e)
        } else {
          console.log('Default MouseEnter Event of : ', e)
        }
      }}
      onMouseLeave={(e) => {
        e.preventDefault()
        if (onMouseLeave !== undefined) {
          onMouseLeave(e)
        } else {
          console.log('Default MouseLeave Event of : ', e)
        }
      }}
      {...(
        gTagEvent !== undefined ? {
          onClick: gTagClick
        } : isExternalLink ? {
          href: url,
          target: props.target && props.target.toLowerCase() == '_blank' ? props.target : '_self'
        } : onClick !== undefined ? {
          onClick: (e) => {
            onClick(e)
          }
        } : null
      )}
    >
      <p className={buttonInner}>
        <span className={styles.label[size] || styles.label.regular}>
          {children}
        </span>
        {labelSuffix && <span sx={{}} className={buttonLabelSuffix}>{labelSuffix}</span>}
      </p>
      {ribbonNote !== undefined && ribbonNote !== '' && (
        <span className={buttonRibbon}>
          <span className={ribbonNote ? buttonRibbonInnerWithSuffix : buttonRibbonInner}>{ribbonNote}</span>
        </span>
      )}
    </a>
  )
}

export default Button


export const OutlineButton = (props) => {
  return (
    <Button style={'outline'}
      {...props}
    />
  );
}

export const SecondaryOutlineButton = (props) => {
  return (
    <Button style={'outline'} fill={'secondary'}
      {...props}
    />
  );
}

export const NftOutlineButton = (props) => {
  return (
    <Button style={'outline'} fill={'nft'}
      {...props}
    />
  );
}