import React, { useRef } from "react"
import PropTypes from "prop-types"
import { navigate } from 'gatsby'

const GetOutboundLink = React.forwardRef(({
  children,
  eventProps = {
    event_category: `outbound`,
    event_action: `click`,
  },
  ...props
}, ref) => {

  const isLink = props.href !== undefined;
  const isExternalLink = props.href !== undefined && (props.href.indexOf('http://') >= 0 || props.href.indexOf('https://') >= 0);

  const {
    event_category,
    event_action,
    event_label,
    event_value,
  } = eventProps

  let timeoutOwner;
  let timerActive = useRef(false);

  const callBack = ({redirect, url}) => {
    // console.log('%%%^^^&&&**$%$%$#')
    // console.log('GetOutboundLink -> callback -> url : ', url)
    if (redirect) {
      if (url.indexOf('/') === 0) {
        // console.log('GetOutboundLink -> callback -> navigate to : ', url)
        navigate(url)
      } else {
        document.location = url
      }
    } else if (typeof window !== "undefined" && window.open) {
      window.open(url, '_blank');
    }
  }

  return (
    <a
      ref={ref}
      {...props}
      onClick={e => {
        if (typeof props.funcOnClick === `function`) {
          props.funcOnClick(e)
        }
        let redirect = true
        if (
          e.button !== 0 ||
          e.altKey ||
          e.ctrlKey ||
          e.metaKey ||
          e.shiftKey ||
          e.defaultPrevented
        ) {
          redirect = false
        }
        if (props.target && props.target.toLowerCase() !== `_self`) {
          redirect = false
        }
        e.stopPropagation()
        e.preventDefault()
        if (typeof window !== "undefined" && window.gtag) {
          if (isLink) {
            timerActive.current = true
            timeoutOwner = setTimeout(function () {
              timerActive.current = false
              callBack({redirect, url: props.href})
            }, 800);
          }
          try {
            window.gtag(`event`, event_action || `click`, {
              send_to: "GOOGLE_ANALYTICS_ID",
              event_category: event_category,
              event_label: event_label !== undefined ? event_label : props.href,
              transport_type: redirect ? `beacon` : ``,
              ...(event_value !== undefined && typeof event_value === 'number' ? {
                value: event_value
              } : null),
              ...(isLink ? {
                event_callback: function () {
                  if (timerActive.current) {
                    clearTimeout(timeoutOwner)
                    timerActive.current = false
                    callBack({redirect, url: props.href})
                  }
                }
              } : null),
            })
          } catch(error){
            if (isLink) {
              if (timerActive.current) {
                clearTimeout(timeoutOwner)
                timerActive.current = false
                callBack({redirect, url: props.href})
              }
            }
          }
        } else {
          if (isLink) {
            callBack({redirect, url: props.href})
          }
        }

        return false
      }}
    >
      {children}
    </a>
  )
})

GetOutboundLink.propTypes = {
  href: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
  eventProps: PropTypes.shape({
    event_action: PropTypes.string,
    event_category: PropTypes.string.isRequired,
    event_label: PropTypes.string
  }),
}

export default GetOutboundLink