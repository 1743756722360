/** @jsx jsx */
import React, { useState, createRef } from "react"
import { jsx } from "theme-ui"
import GetOutboundLink from "./get-outbound-link"
import * as styles from './hover-list.module.scss'

const HoverList = ({
  list,
  caption,
  additionalClassName,
  upward = false,
  ...props
}) => {

  const [hoveIn, setHoveIn] = useState(false)
  const listRef = createRef()
  const [listHeight, setListHeight] = useState(0)
  let hoveTimer

  const handleHoveIn = (e) => {
    // console.log(">>% -_- %>> This is Hover In >> ", e)
    // console.log(">>% ^u^ %>> This is ref of list >> ", listRef.current)
    // console.log(">>% ^u^ %>> This is the height of list >> ", listRef.current.clientHeight)
    clearTimeout(hoveTimer)
    setHoveIn(true);
  }

  const handleHoveOut = (e) => {
    // console.log(">>% -_- %>> This is Hover Out >> ", e)
    // console.log(">>% ^u^ %>> This is ref of list >> ", listRef.current)
    // console.log(">>% ^u^ %>> This is the height of list >> ", listRef.current.clientHeight)
    hoveTimer = setTimeout(
      () => {setHoveIn(false)},
      250
    )
  }

  return (
    <div
      {...props}
      className={styles.container + (additionalClassName ? ' ' + additionalClassName : '')}
    >
      <p
        onMouseOver={handleHoveIn}
        onMouseOut={handleHoveOut}
        className={upward ? styles.captionUpward : styles.caption}
      >{caption}</p>
      <div
        onMouseOver={handleHoveIn}
        onMouseOut={handleHoveOut}
        sx={{
          // display: hoveIn ? `block` : `none`,
          opacity: hoveIn ? 1 : 0,
          height: hoveIn ? listHeight : 0,
          // height: hoveIn ? listRef.current.clientHeight : 0,
        }}
        className={upward ? styles.listUpward : styles.list}
      >
        {list !== undefined && list.length > 0 && (
          <ul ref={el => {
            if (!el) return
            const elStyle = el.currentStyle || window.getComputedStyle(el)
            const elHeight = el.clientHeight + parseInt(elStyle.marginTop) + parseInt(elStyle.marginBottom)
            if (elHeight > listHeight) {
              setListHeight(elHeight)
            }
            // console.log(">>% ^u^ %>> what is el ? >> ", el)
            // console.log(">>% ^u^ %>> what is height of el ? >> ", el.clientHeight)
            // console.log(">>% ^u^ %>> what is style of el ? >> ", elStyle)
            // console.log(">>% ^u^ %>> what is margin top of el ? >> ", elStyle.marginTop)
            // console.log(">>% ^u^ %>> what is margin bottom of el ? >> ", parseInt(elStyle.marginBottom))
          }}>
            {
              list.map((itm, index)=>{
                return (
                  <li key={'hover-list-item-' + index}>
                    <GetOutboundLink {...itm} >
                      {itm.label}
                    </GetOutboundLink>
                  </li>
                )
              })
            }
          </ul>
        )}
      </div>
    </div>
  )
}

export default HoverList