/** @jsx jsx */
import * as React from "react"
import { jsx } from "theme-ui"
import * as styles from './overlay-loading.module.scss'
import commonUtil from "../utils/common-util"
import AppContext from "../context/AppContext"

const OverlayLoading = ({
  isLoading,
  message = ''
}) => {
  return (
    <div
      sx={{
        opacity: isLoading ? 1 : 0,
        pointerEvents: !isLoading ? `none` : `auto`,
        p: {
          fontWeight: `500`, opacity: 0.6
        }
      }}
      className={styles.container}
    >
      <div
        sx={{
            transform: isLoading ? `translateY(0)` : `translateY(42%)`
          }}
        className={styles.inner}
      >
        <p>{message}</p>
      </div>
      <div className={styles.spinContainer}>
        <div className={styles.spinner}></div>
      </div>
    </div>
  )
}

export default OverlayLoading